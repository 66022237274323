<template>
  <v-container>
  <h1 style="margin-bottom: 3%; margin-top: 3%; color: #2596be">MENTIONS LEGALES</h1>
    <div>
      <p>La plateforme OCS GE2 (OCcupation du Sol à Grand Echellede la région Grand Est)  est réalisée et gérée enpartenariat entre l’Etat en région et la Région GrandEst dans le cadre du partenariat GéoGrandEst.</p>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>Secrétariat Général pour les Affaires Régionales et Européennes (SGARE)</li>
      </ul>
      <p>5 place de la République<br/>67000 STRASBOURG</p>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>Direction Régionale de l’Environnement, de l’Aménagement et du Logement (DREAL)</li>
      </ul>
      <p>2 rue Augustin Fresnel - CS95038<br/>57071 METZ CEDEX 03</p>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>Région Grand Est</li>
      </ul>
      <p>1 place Adrien Zeller<br/>7070 STRASBOURG Cedex</p>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>HEBERGEMENT ET REALISATION</h2>
    <div>
      <p>
        <span style="font-weight: bold">Kermap</span>
        <br/>
        1137a Avenue des Champs Blancs
        <br/>
        33510 Cesson-Sévigné
        <br/>
        06-03-67-42-11
        <br/>
        hello@kermap.com
        <br/>
        <span style="margin-top: 2%; margin-bottom: 2%">
          Siège : 2 Rue de la Châtaigneraie
          <br/>
          33510 Cesson-Sévigné
        </span>
        <br/>
        KERMAP
        <br/>
        832 361 471 R.C.S. RENNES
        <br/>
        Greffe du Tribunal de Commerce de RENNES
      </p>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>FINANCEMENT</h2>
    <div>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>La Région Grand Est</li>
      </ul>
      <v-img
        :src="require('@/assets/images/logo_grandest_blank.png')"
        :lazy-src="require('@/assets/images/logo_grandest_blank.png')"
        max-height="100px"
        max-width="200px"
        contain
        style="position: relative; left: 0"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
            indeterminate
            color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>L'Etat</li>
      </ul>
      <v-img
        :src="require('@/assets/images/logo_prefet.png')"
        :lazy-src="require('@/assets/images/logo_prefet.png')"
        max-height="100px"
        max-width="200px"
        contain
        style="position: relative; left: 0"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
            indeterminate
            color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <ul style="margin-bottom: 2%; margin-top: 2%">
        <li>L’Union Européenne (Fonds Européens de Développement Régional)</li>
      </ul>
      <v-img
        :src="require('@/assets/images/ue-feder_blank.png')"
        :lazy-src="require('@/assets/images/ue-feder_blank.png')"
        max-height="100px"
        max-width="200px"
        contain
        style="position: relative; left: 0"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
            indeterminate
            color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>DONNEES A CARACTERE PERSONNEL</h2>
    <div>
      <p>Aucune collecte de donnée à caractère personnel n’est réalisée.</p>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>RESPONSABILITE</h2>
    <div>
      <p>
      GéoGrandEst ne saurait être tenu pour responsabledes erreurs matérielles qui se seraient glissées dans les documents présents sur la plateforme, malgré tout le soin apporté à leur publication.
      </p>
      <p>
      GéoGrandEst ne saurait non plus être tenu pour responsable des informations, propos et opinions émis sur les sites vers lesquels GéoGrandEst les dirige par des liens hypertexte et dont il n’a pas la maîtrise éditoriale.
      </p>
      <p>
      GéoGrandEst ne peut pas plus être tenu pour responsablede la transmission défectueuse des données dues aux divers réseaux de l’Internet ou aux incompatibilités dues au navigateur de l’utilisateur.
      </p>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>DROITS D'AUTEUR ET PROPRIETE INTELLECTUELLE</h2>
    <div>
      <p>La structure générale, ainsi que les textes, photos, images et sons composant ce site sont la propriété de GéoGrandEst. Toute reproduction, totale ou partielle, et toute représentation du contenu substantiel de ce site, d’un ou de plusieurs de ses composants, par quelque procédé que ce soit,sans autorisation expresse de GéoGrandEst, est interdite, et constitue une contrefaçon sanctionnée par les articles L.335-2 et suivants du Code de la propriété intellectuelle.</p>
      <p>Les informations, pictogrammes, photographies, images, textes, séquences vidéo, animées sonores ou non, et autres documents présents sur le site sont protégés par des droits de propriété industrielle et/ou intellectuelle, soit que GéoGrandEst en soit le titulaire, soit qu’il soit autorisé à les reproduire et les représenter.</p>
      <p>A ce titre, toute reproduction, représentation, adaptation, traduction et/ou modification, partielle ou intégrale ou transfert sur un autre site sont interdits.</p>
      <p>La copie sur support papier à usage privé de ces différents objets de droits est autorisée conformément à l’article L122-5 du Code de la Propriété Intellectuelle. Leur reproduction partielle ou intégrale, sans l’accord préalable et écrit de l’auteur, est strictement interdite.</p>
    </div>
    <h2 style="margin-bottom: 3%; margin-top: 3%; color: #2596be"><v-icon color="#2596be" style="margin-right: 8px">mdi-play</v-icon>A PROPOS DES COOKIES</h2>
    <div>
      <p>GeoGrandEst peut utiliser différents cookies sur lesite pour permettre le bon fonctionnement du siteet des services.</p>
      <p>Qu'est-ce qu'un "cookie" ?</p>
      <p>Un "cookie" est un fichier de taille limitée, généralement constitué de lettres et de chiffres, envoyé par le serveur internet au fichier cookie du navigateur situé sur votre équipement numérique.</p>
      <p>Ces cookies permettent au site de fonctionner de manière optimale. Vous pouvez vous y opposer et les supprimer en utilisant les paramètres de votre navigateur, cependant votre expérience utilisateur risque d’être dégradée.</p>
      <p>Cookies nécessaires au site pour fonctionner :</p>
      <v-data-table
        :headers="headers"
        :items="values"
        class="elevation-1"
        hide-default-footer
      ></v-data-table>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'Legal',
  data () {
    return {
      headers: [
        {
          text: 'Catégories de COOKIE',
          align: 'start',
          sortable: false,
          value: 'name',
        },
        { text: 'FINALITE', value: 'description' },
      ],
      values: [
        {
          name: 'Matomo (PK_SES, PK_ID)',
          description: 'Statistiques Matomo (ex Piwik)'
        }
      ]
    }
  }
}
</script>